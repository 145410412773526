body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Modal Button */
.modalButton {
  position: fixed;
  top: 30%;
  right: 10%;
  transform: translate(10%, -30%);
  z-index: 1;
  border-radius: 50%;
  color: rgb(27, 2, 2);
  margin-top: auto;
  padding: 8px 16px;

  background-color: #110f0f;
  padding: 8px 16px;
  
}

.camera {
  position: fixed;
  top: 27%;
  right: 5%;
  transform: translate(10%, -30%);
  z-index: 2;
  border-radius: 50%;
  color: rgb(245, 244, 244);

  background-color: #110f0f;
  padding: 8px 16px;
}
  

/* Modal */
.overlay {
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: fixed;
  width: 100%;
  max-height: 95vh;
}

.modalContainer {
 
  max-width: 70%;
  top: 50%;
  position: fixed;
  max-height: 95vh;
 
  left: 50%;
 
  transform: translate(-50%, -50%);
  
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;

  overflow-y: auto;
  max-height: 100vh;
  align-items: center;
  display: flex;
 

  
}

.videoOverlay {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 95vh;
  object-fit: contain;
}

.videoModalContainer {
   max-width: 100%;
  top: 50%;
  position: fixed;
 
 
  left: 50%;
 
  transform: translate(-50%, -50%);
  
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;

  overflow-y: scroll;
  max-height: 100vh;
  align-items: center;
  display: flex;
 
  
 

}

.videoButton {
  position: fixed;
  top: 19.0%;
  right: 5%;
  transform: translate(10%, -30%);
  z-index: 1;
  border-radius: 50%;
  color: rgb(233, 231, 231);

  background-color: #110f0f;
  padding: 8px 16px;
  
  
  
}


.videoButton:hover {
  background-color: #646065;
  color: #ffffff;
  
}

.camera:hover {
  background-color: #646065;
  color: #ffffff;
  animation: pulse 2s infinite;
}



.contactContainer {
  max-width: 60%;
  bottom: 40%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: #ffffff;
box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
border-radius: 8px;
overflow-y: auto;
max-height: 100vh;
align-items: center;
display: flex;
scroll-snap-type: mandatory;


 


}

.contactOverlay {
  position: relative;
  width: 100%;
  max-height: 95vh;
  
}

.scroll {
  position: fixed;
  top: 35%;
  right: 5%;
  transform: translate(10%, -30%);
  z-index: 1;
  border-radius: 50%;
  color: rgb(233, 231, 231);

  background-color: #110f0f;
  padding: 8px 16px;
  
  

}

.scroll:hover {
  background-color: #646065;
  color: #ffffff;
  animation: pulse 2s infinite;
}
.h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: aqua;
  top: 3.3%;
}
img {
  width: 250px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.main 
   {
 
  justify-content: center;
  align-items: center;
  display: flex;


 
    
   
  }


.modalRight {
  width: 100%;
}

.closeBtn {
  position: fixed;
  bottom: 10px;
  right: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: #411b57;
  color: #ffffff;
  font-size: 1.2rem;
  background-color: aqua;
}

.content {
  display: contain;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
  padding: 1rem 2rem;
  overflow-y:scroll;

 
  
}

.btnContainer {
  display: flex;
  padding: 1rem 1rem;
}
.btnContainer button {
  width: 100%;
  margin: .5rem;
  padding: 16px 0;
  /* border: none; */
  border: 1px solid #411b57;
  /* color: #ffffff; */
}

.btnPrimary {
  background-color: #411b57;
  color: white;
}

.btnOutline {
  /* background-color: #a76a99; */
  background-color: white;
  color: #411b57;
}

.bold {
  font-weight: 600;
}

/* Gallery */
  img {
    width: 100%;
    max-height: 70vh;
    object-fit: cover;
  }

  .heading {
    margin: 1rem;
  }




  .movie_card{
    padding: 0 !important;
    width: 15rem;
    margin:14px; 
    border-radius: 10px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 15px 0 rgba(0, 0, 0, 0.19);
    
  }
  .movie_card img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 20rem;
  }
  .movie_Year{
    color: #5e5c5c;
  }
  
  .movie_info i{
    font-size: 20px;
  }
  .card-title{
    width: 100%;
    height: 4rem;
  }
  
  .card{
   padding-left: 50%;
   
  }