* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
body {
	font-family: "Lato", sans-serif;

background-color: black;


}

h1,
h2,
h3 {
	color: rgb(47, 45, 45);
}

h3,
h4 {
	font-weight: 400;
	color: rgb(114, 113, 113);
}


.gallery {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 1rem;
	height: 50%
	
}